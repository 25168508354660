import axios from "axios";

const url = "https://api.funerariaferran.com/public/api/";

const Login = async (email, password) => {
	let message;
	await axios
		.post(url + "login", {
			email: email,
			password: password,
		})
		.then((response) => {
			if (response.status === 200) {
				localStorage.setItem("isLoggedIn", true);
				localStorage.setItem("user", JSON.stringify(response.data));
				message = "Login amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "error";
			}
		})
		.catch((error) => {
			message = "error";
			console.log(error);
		});

	return message;
};

const getUser = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let get_user;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "user", config)
		.then((response) => {
			if (response.status === 200) {
				get_user = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.setItem("isLoggedIn", false);
			}
		});
	return { get_user, message };
};

const getNecrologiques = async (publicat) => {
	let message;
	let difunts;
	await axios
		.get(url + "difunts?publicat=" + publicat)
		.then((response) => {
			if (response.status === 200) {
				difunts = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { difunts, message };
};

const getNecrologiquesList = async (pagination, page, search) => {
	let message;
	let difunts;
	await axios
		.get(url + "difuntsList?pagination=" + pagination + "&page=" + page + "&search=" + search)
		.then((response) => {
			if (response.status === 200) {
				difunts = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { difunts, message };
};

const createDifunt = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();
	data.append("nom", values.nom);
	data.append("cognoms", values.cognoms);
	data.append("cerimonia", values.cerimonia);
	data.append("dia_cerimonia", values.dia_cerimonia);
	data.append("hora_cerimonia", values.hora_cerimonia);
	data.append("data_defuncio", values.data_defuncio);
	data.append("data_naixement", values.data_naixement);
	data.append("desti", values.desti);
	data.append("sala", values.sala);
	data.append("tanatori_id", values.tanatori_id);
	data.append("tanatori_extern", values.tanatori_extern);
	data.append("horari_tanatori", values.horari_tanatori);
	data.append("user_id", user.user_id);
	data.append("sobrenom", values.sobrenom);
	data.append("viudu", values.viudu);
	data.append("publicat", values.publicat ? 1 : 0);
	data.append("esborrany", values.esborrany ? 1 : 0);
	if (values.imatge) data.append("imatge", values.imatge, values.imatge.name);
	let message;
	let difunt = "";
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "difunts", data, config).then((response) => {
		if (response.status === 200) {
			message = "Difunt guardat amb èxit";
			difunt = response.data.data;
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear el difunt";
		}
	});

	return { message, difunt };
};

const updateDifunt = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();
	let message;
	data.append("nom", values.nom);
	data.append("cognoms", values.cognoms);
	data.append("cerimonia", values.cerimonia);
	data.append("dia_cerimonia", values.dia_cerimonia);
	data.append("hora_cerimonia", values.hora_cerimonia);
	data.append("data_defuncio", values.data_defuncio);
	data.append("data_naixement", values.data_naixement);
	data.append("desti", values.desti);
	data.append("sala", values.sala);
	data.append("tanatori_id", values.tanatori_id);
	data.append("tanatori_extern", values.tanatori_extern);
	data.append("horari_tanatori", values.horari_tanatori);
	data.append("user_id", user.user_id);
	data.append("sobrenom", values.sobrenom);
	data.append("viudu", values.viudu);
	data.append("publicat", values.publicat ? 1 : 0);
	data.append("esborrany", values.esborrany ? 1 : 0);
	if (values.imatge) data.append("imatge", values.imatge, values.imatge.name);

	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "difunts/" + key, data, config).then((response) => {
		if (response.status === 200) {
			message = "Difunt actualitzat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar el difunt";
		}
	});

	return message;
};

const changePublicada = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.get(url + "changePublicada/" + key, config).then((response) => {
		if (response.status === 200) {
			message = "Necrològica actualitzada amb èxit";
		} else {
			message = "Error al editar la necrològica";
		}
	});

	return message;
};

const changeArxivada = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.get(url + "changeArxivada/" + key, config).then((response) => {
		if (response.status === 200) {
			message = "Necrològica actualitzada amb èxit";
		} else {
			message = "Error al editar la necrològica";
		}
	});

	return message;
};

const deleteImage = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "deleteImage/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Fotografia eliminada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar la fotografia";
		}
	});

	return message;
};

const getDifunt = async (key, slug) => {
	let message;
	let difunt;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "difunts/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				difunt = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { difunt, message };
};

const getDifuntSlug = async (slug) => {
	let message;
	let difunt;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "difuntsSlug/" + slug, config)
		.then((response) => {
			if (response.status === 200) {
				difunt = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			message = 400;
			console.log(error);
		});

	return { difunt, message };
};

const deleteDifunt = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "difunts/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar el tanatori";
		}
	});

	return message;
};

const getTanatoris = async () => {
	let message;
	let tanatoris;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "tanatoris", config)
		.then((response) => {
			if (response.status === 200) {
				tanatoris = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { tanatoris, message };
};

const getTanatori = async (key) => {
	let message;
	let tanatori;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "tanatoris/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				tanatori = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { tanatori, message };
};

const createTanatori = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const newValues = { ...values, user_id: user.user_id };
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "tanatoris", newValues, config).then((response) => {
		if (response.status === 200) {
			message = "Tanatori guardat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear tanatori";
		}
	});

	return message;
};

const updateTanatori = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.put(url + "tanatoris/" + key, values, config).then((response) => {
		if (response.status === 200) {
			message = "Informació actualitzada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar la informació";
		}
	});

	return message;
};

const deleteTanatori = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "tanatoris/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar el tanatori";
		}
	});

	return message;
};

const getInformacio = async () => {
	let message;
	let informacio;
	await axios
		.get(url + "informacions")
		.then((response) => {
			if (response.status === 200) {
				informacio = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { informacio, message };
};

const createInformacio = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "informacions", values, config).then((response) => {
		if (response.status === 200) {
			message = "Informació guardada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear la informació";
		}
	});

	return message;
};

const deleteInformacio = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "informacions/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar el tanatori";
		}
	});

	return message;
};

const updateInformacio = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.put(url + "informacions/" + key, values, config).then((response) => {
		if (response.status === 200) {
			message = "Informació actualitzada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar la informació";
		}
	});

	return message;
};

const getContacte = async () => {
	let message;
	const user = JSON.parse(localStorage.getItem("user"));
	let contact;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.get(url + "contact", config)
		.then((response) => {
			if (response.status === 200) {
				contact = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { contact, message };
};

const createContacte = async (values) => {
	let message;
	let config = {
		headers: {
			Accept: "application/json",
		},
	};
	try {
		await axios.post(url + "contact", values, config).then((response) => {
			if (response.status === 200) {
				message = "Enviat. Gràcies pel missatge";
			} else {
				message = "Error al enviar el missatge";
			}
		});
	} catch (error) {
		message = "Error al enviar el missatge";
	}

	return message;
};

const deleteContacte = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "contact/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar el registre";
		}
	});

	return message;
};

const getFlors = async () => {
	let message;
	let flors;
	await axios
		.get(url + "flors")
		.then((response) => {
			if (response.status === 200) {
				flors = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { flors, message };
};

const getFlorsNotes = async (notes) => {
	let message;
	let flors;
	await axios
		.get(url + "flors_notes/" + notes)
		.then((response) => {
			if (response.status === 200) {
				flors = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { flors, message };
};

const createFlor = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();
	data.append("nom", values.nom);
	data.append("sku", values.nom);
	data.append("mida", values.mida);
	data.append("descripcio", values.descripcio);
	data.append("preu", values.preu);
	data.append("flor_categoria_id", values.flor_categoria_id);
	if (values.imatge.name) {
		data.append("imatge", values.imatge, values.imatge.name);
	}
	if (values.detalls) {
		for (const file of values.detalls) {
			data.append("detalls[]", file);
		}
	}
	let message;

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "flors", data, config).then((response) => {
		if (response.status === 200) {
			message = "Flor guardada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear la flor";
		}
	});

	return message;
};

const updateFlor = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const data = new FormData();
	let message;
	data.append("nom", values.nom);
	data.append("sku", values.nom);
	data.append("mida", values.mida);
	data.append("descripcio", values.descripcio);
	data.append("preu", values.preu);
	data.append("flor_categoria_id", values.flor_categoria_id);
	if (values.imatge.name) {
		data.append("imatge", values.imatge, values.imatge.name);
	}
	if (values.detalls) {
		for (const file of values.detalls) {
			data.append("detalls[]", file);
		}
	}
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "flors/" + key, data, config).then((response) => {
		if (response.status === 200) {
			message = "Flor actualitzada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar la flor";
		}
	});

	return message;
};

const getFlor = async (key) => {
	let message;
	let flor;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "flors/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				flor = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { flor, message };
};

const deleteFlor = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "flors/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar la flor";
		}
	});

	return message;
};

const getNotes = async (id) => {
	let message;
	const user = JSON.parse(localStorage.getItem("user"));
	let notes;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.get(url + "notes/" + id, config)
		.then((response) => {
			if (response.status === 200) {
				notes = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { notes, message };
};

const createNota = async (values, id) => {
	let message;
	const newValues = { ...values, difunt_id: id };
	let config = {
		headers: {
			Accept: "application/json",
		},
	};
	try {
		await axios.post(url + "notes", newValues, config).then((response) => {
			if (response.status === 200) {
				message = "Nota de condol enviada";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "Error al enviar la nota de condol";
			}
		});
	} catch (error) {
		message = "Error al enviar la nota de condol";
	}

	return message;
};

const deleteNota = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "notes/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar el registre";
		}
	});

	return message;
};

const getFlorCategoria = async () => {
	let message;
	let categories;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "florCategories", config)
		.then((response) => {
			if (response.status === 200) {
				categories = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { categories, message };
};

const getCategoria = async (key) => {
	let message;
	let categoria;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "florCategories/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				categoria = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { categoria, message };
};

const createFlorCategoria = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "florCategories", values, config).then((response) => {
		if (response.status === 200) {
			message = "Tanatori guardat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear tanatori";
		}
	});

	return message;
};

const updateFlorCategoria = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "florCategories/" + key, values, config).then((response) => {
		if (response.status === 200) {
			message = "Informació actualitzada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar la informació";
		}
	});

	return message;
};

const deleteFlorCategoria = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "florCategories/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar el tanatori";
		}
	});

	return message;
};

const getOrders = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let orders;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.get(url + "orders?key=" + key, config)
		.then((response) => {
			if (response.status === 200) {
				orders = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { orders, message };
};

const getOrder = async (key) => {
	let message;
	let newOrder;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "orders/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				newOrder = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { newOrder, message };
};

const createOrder = async (values) => {
	let message;
	let form;
	await axios.post(url + "orders", values).then((response) => {
		if (response.status === 200) {
			console.log(response);
			form = response.data;
			message = "Comanda creada. Esperi...";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear la informació";
		}
	});

	return { message, form };
};

const deleteOrder = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "orders/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar el tanatori";
		}
	});

	return message;
};

const updateOrder = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.put(url + "orders/" + key, values, config).then((response) => {
		if (response.status === 200) {
			message = "Informació actualitzada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar la informació";
		}
	});

	return message;
};

const getOrderStates = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let orderStates;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.get(url + "orderStates", config)
		.then((response) => {
			if (response.status === 200) {
				orderStates = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { orderStates, message };
};

const changeState = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "changeState/" + key, values, config).then((response) => {
		if (response.status === 200) {
			message = "Estat actualitzat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar l'estat";
		}
	});

	return { message };
};

const changeFloristeria = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "changeFloristeria/" + key, values, config).then((response) => {
		if (response.status === 200) {
			message = "Floristeria actualitzada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar l'estat";
		}
	});

	return { message };
};

const getFloristeries = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let floristeries;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.get(url + "floristeries", config)
		.then((response) => {
			if (response.status === 200) {
				floristeries = response.data.data;

				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { floristeries, message };
};

const getFloristeria = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let floristeria;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.get(url + "floristeries/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				floristeria = response.data.data;

				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { floristeria, message };
};

const createFloristeria = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "floristeries", values, config).then((response) => {
		if (response.status === 200) {
			message = "Tanatori guardat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear tanatori";
		}
	});

	return message;
};

const updateFloristeria = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "floristeries/" + key, values, config).then((response) => {
		if (response.status === 200) {
			message = "Informació actualitzada amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar la informació";
		}
	});

	return message;
};

const deleteFloristeria = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "floristeries/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar el tanatori";
		}
	});

	return message;
};

const getEslogans = async () => {
	let message;
	let eslogans;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "eslogans", config)
		.then((response) => {
			if (response.status === 200) {
				eslogans = response.data.data;

				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { eslogans, message };
};

const getEslogansPublic = async () => {
	let message;
	let eslogans;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + "eslogansPublic", config)
		.then((response) => {
			if (response.status === 200) {
				eslogans = response.data.data;

				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { eslogans, message };
};

const createEslogan = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "eslogans", values, config).then((response) => {
		if (response.status === 200) {
			message = "Eslogan guardat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear tanatori";
		}
	});

	return message;
};

const updateEslogan = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "eslogans/" + key, values, config).then((response) => {
		if (response.status === 200) {
			message = "Eslogan actualitzat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al editar la informació";
		}
	});

	return message;
};

const deleteEslogan = async (id) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "eslogans/" + id, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al eliminar el tanatori";
		}
	});

	return message;
};

const changeEslogan = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.get(url + "changeEslogan/" + key, config).then((response) => {
		if (response.status === 200) {
			message = "Eslogan actualitzat amb èxit";
		} else {
			message = "Error al editar l'eslogan";
		}
	});

	return message;
};

const getPagaments = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let pagaments;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.get(url + "pagaments", config)
		.then((response) => {
			if (response.status === 200) {
				pagaments = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { pagaments, message };
};

const createPagament = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let form;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.post(url + "pagaments", values, config).then((response) => {
		if (response.status === 200) {
			console.log(response);
			form = response.data;
			message = "Comanda creada. Esperi...";
		}
		if (response.data.status === "failed" && response.data.success === undefined) {
			message = "Error al crear la informació";
		}
	});

	return { message, form };
};

const getVivo = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let data;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.get(url + "getVivo/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				data = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { data, message };
};

const createVivo = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let data;
	let success;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.post(url + "createVivo", values, config)
		.then((response) => {
			if (response.status === 200) {
				data = response.data.data;
				message = "Publicat amb èxit";
				success = true;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
				success = false;
			}
		})
		.catch((error) => {
			console.log(error);
			success = false;
		});

	return { message, success, data };
};

const editVivo = async (values, id) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let data;
	let success;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios
		.post(url + "editVivo/" + id, values, config)
		.then((response) => {
			if (response.status === 200) {
				data = response.data.data;
				message = "Editat amb èxit";
				success = true;
			}
			if (response.data.status === "failed" && response.data.success === undefined) {
				message = "No s'han trobat";
				success = false;
			}
		})
		.catch((error) => {
			console.log(error);
			success = false;
		});

	return { message, success, data };
};

export {
	Login,
	getNecrologiques,
	getDifunt,
	getDifuntSlug,
	createDifunt,
	updateDifunt,
	deleteDifunt,
	getTanatoris,
	getTanatori,
	createTanatori,
	deleteTanatori,
	updateTanatori,
	getInformacio,
	createInformacio,
	deleteInformacio,
	updateInformacio,
	getContacte,
	createContacte,
	deleteContacte,
	createFlor,
	updateFlor,
	getFlor,
	getFlors,
	getFlorsNotes,
	deleteFlor,
	getNotes,
	createNota,
	deleteNota,
	deleteImage,
	getFlorCategoria,
	getCategoria,
	createFlorCategoria,
	updateFlorCategoria,
	deleteFlorCategoria,
	createOrder,
	getOrders,
	updateOrder,
	deleteOrder,
	getUser,
	getOrderStates,
	changeState,
	getOrder,
	changePublicada,
	changeFloristeria,
	getFloristeries,
	createFloristeria,
	updateFloristeria,
	deleteFloristeria,
	getEslogans,
	createEslogan,
	updateEslogan,
	deleteEslogan,
	getFloristeria,
	changeEslogan,
	getEslogansPublic,
	getPagaments,
	createPagament,
	getNecrologiquesList,
	changeArxivada,
	getVivo,
	createVivo,
	editVivo,
};
