import React, { useEffect, useState } from "react";
import { Box, CircularProgress, createMuiTheme, Fade, makeStyles, MuiThemeProvider } from "@material-ui/core";
import { useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import Columns from "../columns/NotesColumns";
import { useSnackbar } from "notistack";
import { deleteNota, getNotes } from "../../../../database/API";
import Title from "../../../../components/Title";

makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingTop: 40,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
}));

const getMuiTheme = () =>
	createMuiTheme({
		overrides: {
			TableHead: {
				root: {
					fontSize: 23,
				},
			},
		},
	});

const Notes = () => {
	const { key } = useParams();
	const [notes, setNotes] = useState();
	const [loading, setLoading] = useState(true);
	const columns = Columns();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const get = async () => {
			const { notes } = await getNotes(key);
			setNotes(notes);
			setLoading(false);
			console.log(notes);
		};

		get();
	}, [key]);

	const options = {
		enableNestedDataAccess: ".",
		filterType: "checkbox",
		selectableRows: false,
		rowsPerPageOptions: [10, 25, 50, 100],
		onRowsDelete: (rowsDeleted) => {
			rowsDeleted.data.forEach(async (item) => {
				let message = await deleteNota(notes[item.dataIndex].id);
				enqueueSnackbar(message, {
					variant: "success",
				});
				console.log(message);
			});
		},
		textLabels: {
			body: {
				noMatch: "No hi han notes",
				toolTip: "Ordenar",
				columnHeaderTooltip: (column) => `${"Ordenat per"} ${column.label}`,
			},
			pagination: {
				next: "Següent pàgina",
				previous: "Pàgina anterior",
				rowsPerPage: "Files per pàgina:",
				displayRows: "de", // 1-10 of 30
			},
			toolbar: {
				search: "Cercar",
				downloadCsv: "Descarregar CSV",
				print: "Imprimir",
				viewColumns: "Veure columnes",
				filterTable: "Filtrar taula",
			},
			filter: {
				title: "FILTRES",
				reset: "resetejar",
				all: "Tots",
			},
			viewColumns: {
				title: "Mostrar columnes",
			},
			selectedRows: {
				text: "Fila/es eliminades",
				delete: "Eliminar",
			},
		},
	};

	return (
		<Box mb={4} mt={2}>
			{!loading ? (
				<Fade in={!loading}>
					<div>
						<MuiThemeProvider theme={getMuiTheme()}>
							<MUIDataTable data={notes} columns={columns} options={options} />
						</MuiThemeProvider>
					</div>
				</Fade>
			) : (
				<CircularProgress />
			)}
		</Box>
	);
};

export default Notes;
