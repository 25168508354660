import React from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import theme from '../../../../theme';
import ScrollAnimation from 'react-animate-on-scroll';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        justifyContent: 'center',
    },
    desktop: {
        height: '100vh',
    },
    mobile: {
        paddingTop: 50,
        paddingBottom: 50,
    },
    text: {
        textAlign: 'center',
    },
    columna1: {
        borderLeft: '3px solid',
        borderLeftColor: theme.palette.text.primary,
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    dist: {
        marginLeft: 30,
    },
    dist2: {
        marginLeft: 30,
        paddingBottom: 20,
    },
}));

const QuiSom = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:960px)');
    const { t } = useTranslation();

    return (
        <div
            className={clsx(
                classes.main,
                matches ? classes.desktop : classes.mobile
            )}
        >
            <Container maxWidth="lg">
                <ScrollAnimation
                    animateIn="fadeIn"
                    style={{ transition: '0.5s' }}
                >
                    <Grid container spacing={4}>
                        <Grid
                            item
                            md={12}
                            className={matches ? classes.columna1 : ''}
                        >
                            <Typography
                                variant="h2"
                                className={matches ? classes.dist2 : ''}
                            >
                                {t('Els Ferran')}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid
                            item
                            md={6}
                            className={matches ? classes.columna1 : ''}
                        >
                            <Typography className={matches ? classes.dist : ''}>
                                Tot va començar fa una pila d’anys. Molts.
                                <br /> La bona dona era una dona de poble i
                                veïnat. Amatent als del seu carrer, sabia prou
                                que les veïnes fan més servei a les males hores
                                que a les bones. A les bones, cadascú s’ho fa a
                                casa, a les males hom necessita un cop de mà
                                dels de la casa del costat. No debades, a pagès,
                                el primer veí té cura de tot en els tràngols.{' '}
                                <br />
                                La pobre dona feia més que de bona veïna. Quan
                                potser ningú no s’hi veia amb cor, ella vestia
                                el darrer vestit a aquell que acabava de dir el
                                darrer adéu. No va trigar a ser demanada en
                                aquest menester arreu de la nostra ciutat.
                                D’ella ve el motiu dels Ferran, conegut de tots.
                                En aquells dies de la bona dona, un dels homes
                                de la família feia de fuster ensinistrat a
                                l’ofici que acabava el servei de la seva
                                parenta. Així, any rera any, desenes d’anys rera
                                desenes d’anys, els Ferran han continuat la
                                tasca del fuster i pola bona dona. Sabien i
                                encara saben prou que no és tasca agradosa, però
                                qui se’ls hagi trobat a la vora en aquelles
                                hores, ha sentit com s’ho fan silenciosament,
                                tant com poden, amb el punt just de condol per
                                no fer estrips en el dol i mirant de llevar a la
                                família els tràfecs que duu d’afegitó aquell
                                pas. Mentrestant, els temps canviaven.
                                <br /> Hi havia més gent, començava a
                                amuntegar-se a ciutat, les cerimònies de
                                l’església es feien apressades, el lloc de repòs
                                restava en l’anonimat conegut només pels propers
                                d’amistat o de sang.
                            </Typography>
                        </Grid>
                        <Grid item md={6} style={{ paddingTop: 0 }}>
                            <Typography className={matches ? classes.dist : ''}>
                                Malaventuradament, a ciutat d’avui dia els
                                serveis en unes hores males són tan freds i
                                impersonals com les darreres estades ja gairebé
                                sense senyal de xiprers. A la nostra ciutat, els
                                Ferran no han perdut pas ni el tracte ni la
                                manera de fer d’aquella bona dona i d’aquell
                                fuster de les primeres. Ho puc ben dir del dia
                                en el qual la meva mare va deixar-me per no
                                deixar-me ja mai més. Però els canvis dels temps
                                ens han arribat fins ací.
                                <br />
                                Hom podia demanar als Ferran un servei
                                renovellat. I ací el teniu, pensat i fet per
                                quins saben prou bé que els calia trobar uns
                                espais que no perdessin la intimitat d’una casa,
                                tan necessària en aquell moment. Els Ferran,
                                venint de generacions enllà, saben pla bé que,
                                encara que tot canvïi, no pot canviar el silenci
                                respectuós, saben com han de continuar aquella
                                cura propera, de veí a veí, que han procurat
                                tenir sempre. I no cal pas dir res més.
                                <br />
                                Perquè en aquestes més val callar i fer costat
                                sense destorbar.
                                <br />
                                <br />
                                Josep M.Ballarín i Monset - Capellà de Queralt
                            </Typography>
                        </Grid>
                    </Grid>
                </ScrollAnimation>
            </Container>
        </div>
    );
};

export default QuiSom;
