import React, { useEffect, useState } from "react";
import { Box, Grid, makeStyles, TextField, Button, InputLabel, Select, FormControl, Fade, Typography, Collapse } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { getTanatoris, getDifunt } from "../../../../database/API";
import { useSnackbar } from "notistack";
import Title from "../../../../components/Title";
import { updateDifunt } from "../../../../database/API";
import moment from "moment";
import Thumb from "../../../../components/Thumb";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingTop: 40,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	formControl: {
		marginTop: 16,
		marginBottom: 15,
	},
	label: {
		color: theme.palette.text.secondary,
	},
}));
const DifuntEdit = () => {
	const classes = useStyles();
	const { key } = useParams();
	const navigate = useNavigate();
	const [tanatoris, setTanatoris] = useState();
	const [difunt, setDifunt] = useState();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);
	const [salesValides, setSalesValides] = useState(3);

	useEffect(() => {
		const get = async () => {
			const { tanatoris } = await getTanatoris();
			setTanatoris(tanatoris);
		};

		get();
	}, []);

	useEffect(() => {
		const get = async () => {
			const { difunt } = await getDifunt(key);
			setDifunt(difunt);
			setLoading(false);
		};
		get();
	}, [key]);

	const crear = async (values) => {
		const message = await updateDifunt(values, key);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate(-1, { state: values });
	};

	const onChangeTanatori = (e, setFieldValue) => {
		const { value } = e.target;
		setFieldValue("tanatori_id", value);
		if (value !== "1000" && value !== "0" && value !== null && value !== "") {
			let t = tanatoris.find((item) => item.id === parseInt(value));
			setFieldValue("sala", 1);
			setSalesValides(t?.sales);
		} else {
			setFieldValue("sala", "");
		}
	};

	return (
		<Box>
			{loading ? (
				<Skeleton height={400} />
			) : (
				<Fade in={!loading}>
					<Grid container spacing={3}>
						<Grid item md={12} xs={12}>
							<Formik
								enableReinitialize={true}
								initialValues={{
									nom: difunt?.nom,
									cognoms: difunt?.cognoms,
									data_naixement: difunt?.data_naixement ? moment(difunt?.data_naixement).format("YYYY-MM-DD") : "",
									data_defuncio: moment(difunt?.data_defuncio).format("YYYY-MM-DD"),
									sala: difunt?.sala ? difunt.sala : "",
									tanatori_id: difunt?.tanatori_id ? difunt.tanatori_id : "",
									tanatori_extern: difunt?.tanatori_extern ? difunt.tanatori_extern : "",
									horari_tanatori: difunt?.horari_tanatori ? difunt.horari_tanatori : "",
									desti: difunt?.desti ? difunt.desti : "",
									cerimonia: difunt?.cerimonia ? difunt.cerimonia : "",
									viudu: difunt?.viudu ? difunt.viudu : "",
									sobrenom: difunt?.sobrenom ? difunt.sobrenom : "",
									dia_cerimonia: difunt?.dia_cerimonia ? moment(difunt?.dia_cerimonia).format("YYYY-MM-DD") : "",
									hora_cerimonia: difunt?.hora_cerimonia ? moment(difunt?.hora_cerimonia, "HH:mm:ss").format("HH:mm") : "",
									imatge: "",
									publicat: difunt?.publicat === 1 ? true : false,
									esborrany: difunt?.esborrany === 1 ? true : false,
								}}
								validationSchema={Yup.object().shape({
									nom: Yup.string().required("El nom és obligatòri"),
									data_defuncio: Yup.string().required("La data de defunció és obligatòria"),
									sala: Yup.number()
										.min(1, "Ha de ser mínim 1")
										.max(salesValides, "Ha de ser maxim " + salesValides),
								})}
								onSubmit={(values) => {
									crear(values);
								}}
							>
								{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
									<form onSubmit={handleSubmit}>
										<Grid container>
											<Grid item md={6} xs={12}>
												<Box mb={3}>
													<Title title={"Modificar"} subtitle={"Modifica les dades"} />
												</Box>
											</Grid>
											<Grid item md={6} xs={12} style={{ textAlign: "right" }}>
												<FormControlLabel
													control={
														<Checkbox
															checked={values.publicat}
															onChange={() => {
																if (!values.publicat) {
																	setFieldValue("esborrany", false);
																}
																setFieldValue("publicat", !values.publicat);
															}}
															name="publicat"
														/>
													}
													label="Publicat"
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={values.esborrany}
															onChange={() => {
																if (!values.esborrany) {
																	setFieldValue("publicat", false);
																}
																setFieldValue("esborrany", !values.esborrany);
															}}
															name="esborrany"
														/>
													}
													label="Arxivat"
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.nom && errors.nom)}
													fullWidth
													helperText={touched.nom && errors.nom}
													label="Nom"
													margin="normal"
													name="nom"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.nom}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.cognoms && errors.cognoms)}
													fullWidth
													helperText={touched.cognoms && errors.cognoms}
													label="Cognoms"
													margin="normal"
													name="cognoms"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.cognoms}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.data_naixement && errors.data_naixement)}
													fullWidth
													helperText={touched.data_naixement && errors.data_naixement}
													label={"Data Naixement"}
													margin="normal"
													name="data_naixement"
													onBlur={handleBlur}
													onChange={handleChange}
													type="date"
													value={values.data_naixement}
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														inputProps: {
															max: moment().format("YYYY-MM-DD"),
														},
													}}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.data_defuncio && errors.data_defuncio)}
													fullWidth
													helperText={touched.data_defuncio && errors.data_defuncio}
													label={"Data Defunció"}
													margin="normal"
													name="data_defuncio"
													onBlur={handleBlur}
													onChange={handleChange}
													type="date"
													value={values.data_defuncio}
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														inputProps: {
															max: moment().format("YYYY-MM-DD"),
														},
													}}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={3} xs={12}>
												<FormControl className={classes.formControl} fullWidth>
													<InputLabel htmlFor="tanatori_id">Tanatòri</InputLabel>
													<Select
														InputLabelProps={{
															shrink: true,
														}}
														native
														value={values.tanatori_id}
														onChange={(e) => {
															onChangeTanatori(e, setFieldValue);
														}}
														onBlur={handleBlur}
														label="Tanatori"
														inputProps={{
															name: "tanatori_id",
															id: "tanatori_id",
														}}
													>
														<option value={null} key={0}></option>
														{tanatoris?.map((tanatori) => {
															return (
																<option value={tanatori.id} key={tanatori.id}>
																	{tanatori.nom}
																</option>
															);
														})}
														<option value={1000} key={1000}>
															Extern
														</option>
													</Select>
												</FormControl>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.sala && errors.sala)}
													fullWidth
													helperText={touched.sala && errors.sala}
													label={"Sala"}
													margin="normal"
													name="sala"
													onBlur={handleBlur}
													onChange={handleChange}
													type="number"
													InputLabelProps={{
														shrink: true,
													}}
													value={values.sala}
													InputProps={{
														inputProps: {
															min: 1,
															max: salesValides,
														},
													}}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<TextField
													error={Boolean(touched.horari_tanatori && errors.horari_tanatori)}
													fullWidth
													helperText={touched.horari_tanatori && errors.horari_tanatori}
													label={"Horari Tanatòri"}
													margin="normal"
													name="horari_tanatori"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													InputLabelProps={{
														shrink: true,
													}}
													value={values.horari_tanatori}
												/>
											</Grid>
										</Grid>
										<Collapse in={values?.tanatori_id === 1000 || values?.tanatori_id === "1000"}>
											<Grid container spacing={3}>
												<Grid item xs={12}>
													<TextField
														error={Boolean(touched.tanatori_extern && errors.tanatori_extern)}
														fullWidth
														helperText={touched.tanatori_extern && errors.tanatori_extern}
														label={"Nom Tanatori Extern (Manual)"}
														margin="normal"
														name="tanatori_extern"
														onBlur={handleBlur}
														onChange={handleChange}
														type="text"
														value={values.tanatori_extern}
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>
											</Grid>
										</Collapse>
										<Grid container spacing={3}>
											<Grid item md={6} xs={12}>
												<TextField
													error={Boolean(touched.cerimonia && errors.cerimonia)}
													fullWidth
													helperText={touched.cerimonia && errors.cerimonia}
													label={"Lloc Cerimònia"}
													margin="normal"
													name="cerimonia"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													InputLabelProps={{
														shrink: true,
													}}
													value={values.cerimonia}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.dia_cerimonia && errors.dia_cerimonia)}
													fullWidth
													helperText={touched.dia_cerimonia && errors.dia_cerimonia}
													label={"Dia Cerimònia"}
													margin="normal"
													name="dia_cerimonia"
													onBlur={handleBlur}
													onChange={handleChange}
													type="date"
													InputLabelProps={{
														shrink: true,
													}}
													value={values.dia_cerimonia}
												/>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.hora_cerimonia && errors.hora_cerimonia)}
													fullWidth
													helperText={touched.hora_cerimonia && errors.hora_cerimonia}
													label={"Hora Cerimònia"}
													margin="normal"
													name="hora_cerimonia"
													onBlur={handleBlur}
													onChange={handleChange}
													type="time"
													value={values.hora_cerimonia}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={5} xs={12}>
												<TextField
													error={Boolean(touched.desti && errors.desti)}
													fullWidth
													helperText={touched.desti && errors.desti}
													label={"Destí"}
													margin="normal"
													name="desti"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.desti}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
											<Grid item md={4} xs={12}>
												<TextField
													error={Boolean(touched.viudu && errors.viudu)}
													fullWidth
													helperText={touched.viudu && errors.viudu}
													label={"Vídua/Vidu"}
													margin="normal"
													name="viudu"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.viudu}
												/>
												<Typography style={{ fontSize: 12 }}>Autocompletar:</Typography>
												<IconButton style={{ borderRadius: 50 }} onClick={() => setFieldValue("viudu", "Vidu de")}>
													<Typography style={{ fontSize: 12 }}>M</Typography>
												</IconButton>

												<IconButton style={{ borderRadius: 50 }} onClick={() => setFieldValue("viudu", "Vídua de")}>
													<Typography style={{ fontSize: 12 }}>F</Typography>
												</IconButton>
											</Grid>
											<Grid item md={3} xs={12}>
												<TextField
													error={Boolean(touched.sobrenom && errors.sobrenom)}
													fullWidth
													helperText={touched.sobrenom && errors.sobrenom}
													label={"Sobrenom"}
													margin="normal"
													name="sobrenom"
													onBlur={handleBlur}
													onChange={handleChange}
													type="text"
													value={values.sobrenom}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={3}>
											<Grid item md={12} xs={12}>
												<Box mt={3}>
													<Typography className={classes.label}>Imatge</Typography>
													<input
														id="imatge"
														name="imatge[]"
														type="file"
														onChange={(event) => {
															setFieldValue("imatge", event.currentTarget.files[0]);
														}}
														className="form-control"
													/>
												</Box>
												<Box p={2}>{values.imatge ? <Thumb file={values.imatge} /> : <></>}</Box>
											</Grid>
										</Grid>

										<Box my={2}>
											<Button color="primary" fullWidth size="large" type="submit" variant="contained">
												{"Guardar"}
											</Button>
										</Box>
									</form>
								)}
							</Formik>
						</Grid>
					</Grid>
				</Fade>
			)}
		</Box>
	);
};

export default DifuntEdit;
