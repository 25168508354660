import React, { useEffect, useState } from "react";
import { Box, Container, Fade, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, TextField, Typography, useMediaQuery } from "@material-ui/core";
import { animated, useSpring } from "@react-spring/web";
import Footer from "../home/elements/Footer";
import { getNecrologiques, getNecrologiquesList } from "../../../database/API";
import NecrologicaSkeleton from "../../../components/Skeletons/NecrologicaSkeleton";
import Necrologica from "../../../components/Necrologica";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Pagination } from "@material-ui/lab";
import { Search } from "react-feather";

const TotesNecrologiques = () => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const [loadingNecro, setLoadingNecro] = useState(true);
	const [difunts, setDifunts] = useState([]);
	const [page, setPage] = useState(1);
	const [pages, setPages] = useState(0);
	const [search, setSearch] = useState("");
	const { t } = useTranslation();

	const handlePage = (event, value) => {
		setPage(value);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		const get = async () => {
			setLoadingNecro(true);
			const { difunts } = await getNecrologiquesList(10, page, search);
			setDifunts(difunts?.data);
			setPages(difunts?.last_page >= 6 ? 6 : difunts.last_page);
			setLoadingNecro(false);
		};

		get();
	}, [page, search]);

	return (
		<animated.div>
			<Box maxWidth="lg">
				<Box className={clsx(classes.main, matches ? classes.desktop : classes.mobile)}>
					<Container maxWidth="lg">
						<Box mb={5} display={"flex"} justifyContent="space-between" alignItems={"center"} alignContent="center">
							<Typography variant="h2">{t("Necrològiques recents")}</Typography>
							<FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
								<InputLabel htmlFor="outlined-adornment-password">{t("Cercar")}</InputLabel>
								<OutlinedInput
									id="outlined-adornment-password"
									endAdornment={<Search />}
									label="Cercar"
									style={{ borderRadius: 50 }}
									onChange={(val) => {
										setSearch(val.target.value);
										setPage(1);
									}}
									value={search}
								/>
							</FormControl>
						</Box>
						{!loadingNecro ? (
							<Fade in={!loadingNecro}>
								<Grid container spacing={4}>
									{difunts?.map((data) => {
										return <Necrologica key={data.id} data={data} loading={loadingNecro} list />;
									})}
								</Grid>
							</Fade>
						) : (
							<NecrologicaSkeleton list />
						)}
						<Box mt={4} display="flex" justifyContent={"center"}>
							<Pagination onChange={handlePage} page={page} count={pages} />
						</Box>
					</Container>
				</Box>
			</Box>
			<Footer />
		</animated.div>
	);
};

export default TotesNecrologiques;

const useStyles = makeStyles(() => ({
	main: {
		display: "flex",
		flexDirection: "column",
		backgroundImage: `url("${process.env.PUBLIC_URL}/images/fons-tanatoris.jpg")`,
		justifyContent: "center",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPositionX: "right",
		backgroundPositionY: "bottom",
		marginTop: 150,
		marginBottom: 150,
	},
	mobile: {
		marginTop: 50,
		marginBottom: 50,
	},
	noNecro: {
		opacity: 0.6,
		paddingTop: 50,
		paddingBottom: 50,
	},
}));
