const Columns = () => {
    const columns = [
        {
            name: "id",
            label: "id",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "nom",
            label: "Nom",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "missatge",
            label: "Missatge",
            options: {
                filter: false,
                sort: true,
            },
        },
    ];
    return columns;
};

export default Columns;
