import React, { useEffect, useState } from "react";
import { Box, Container, makeStyles, Typography, Grid } from "@material-ui/core";
import { Element } from "react-scroll";
import { getFlorCategoria, getFlorsNotes } from "../../../../database/API";
import FlorPublic from "../../../../components/FlorPublic";
import ScrollAnimation from "react-animate-on-scroll";
import FlorsSkeleton from "../../../../components/Skeletons/FlorsSkeleton";
import Categories from "../../../../components/Categories";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	main: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: "white",
		paddingTop: 100,
		paddingBottom: 100,
	},
	button: {
		boxShadow: "none",
		borderRadius: 30,
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 30,
		paddingRight: 30,
		textTransform: "none",
	},
}));

const Flors = (props) => {
	const classes = useStyles();
	// const matches = useMediaQuery("(min-width:960px)");
	const [flors, setFlors] = useState();
	const [categories, setCategories] = useState();
	const [categoria, setCategoria] = useState(4);
	const { difunt } = props;
	const { t } = useTranslation();

	useEffect(() => {
		const get = async () => {
			const { flors } = await getFlorsNotes(false);
			const { categories } = await getFlorCategoria();
			setCategories(categories);
			setFlors(flors);
			props.setLoadingFlors(false);
		};
		get();
	}, []);

	return (
		<Element name="flors" className={classes.main}>
			<ScrollAnimation animateIn="fadeIn" style={{ transition: "0.5s" }}>
				<Container maxWidth="lg">
					<Box mb={3}>
						<Typography variant="h2">{t("Catàleg floral")}</Typography>
						<Typography>{t("Si teniu intenció d'adquirir flors per a la cerimònia del difunt, ho podeu fer des d'aquí.")}</Typography>
					</Box>
					{!props.loadingFlors ? (
						<>
							<Box style={{ display: "flex" }}>
								{categories?.map((item) => {
									return item.id !== 5 && <Categories key={item.id} item={item} setCategoria={setCategoria} categoria={categoria} />;
								})}
							</Box>
							<Grid container spacing={5}>
								{flors?.map((item) => {
									return <FlorPublic difunt={difunt} key={item.id} categoria={categoria} item={item} />;
								})}
							</Grid>
						</>
					) : (
						<FlorsSkeleton />
					)}
				</Container>
			</ScrollAnimation>
		</Element>
	);
};

export default Flors;
