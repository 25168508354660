import { createMuiTheme, colors } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
    palette: {
        background: {
            dark: '#F4F6F8',
            default: '#F7F6F4',
            alt: colors.common.white,
        },
        primary: {
            main: '#CFCABB',
            hover: '#CFCABB90',
        },
        secondary: {
            main: '#E5E3DB',
        },
        danger: {
            main: colors.red[500],
            light: colors.red[300],
        },
        success: {
            main: colors.green[500],
        },
        text: {
            primary: '#424242',
            secondary: '#CFCABB',
            danger: colors.red[500],
            admin: '#646464',
        },
        typography: {
            fontFamily: 'Red Hat Text',
        },
    },
    overrides: {
        MuiTypography: {
            h1: {
                fontSize: '3rem',
                fontFamily: 'Red Hat Text',
                textTransform: 'uppercase',
                color: '#424242',
                [breakpoints.down('xs')]: {
                    fontSize: '2rem',
                },
            },
            h2: {
                fontSize: '1.5rem',
                fontFamily: 'Red Hat Text',
                fontWeight: 800,
                color: '#424242',
            },
            h3: {
                fontSize: '1.2rem',
                fontFamily: 'Red Hat Text',
                fontWeight: 800,
                color: '#424242',
            },
            h4: {
                fontSize: '1rem',
                fontFamily: 'Red Hat Text',
                fontWeight: 800,
                color: '#424242',
            },
            body1: {
                fontFamily: 'Red Hat Text',
                color: '#424242',
                [breakpoints.down('xs')]: {
                    fontSize: '0.9rem',
                },
            },
            root: {
                '& a': {
                    color: '#5e5e5e',
                    textDecoration: 'none',
                    transition: '0.5s',
                    '&:hover': {
                        color: 'black',
                    },
                },
            },
        },
        '& a': {
            color: 'black',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1150,
            xl: 1920,
        },
    },
});

export default theme;
