import React, { useContext, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Perfil from "./elements/Perfil";
import Notes from "./elements/Notes";
import Flors from "./elements/Flors";
import Footer from "../home/elements/Footer";
import Degradat from "../../../components/Degradat";
import { Info } from "../../../utils/DifuntContext";
import { useLocation, useParams } from "react-router";
import { getDifuntSlug } from "../../../database/API";
import { scroller } from "react-scroll";
import moment from "moment";

const DifuntPublic = () => {
	const { difunt, setDifunt } = useContext(Info);
	const [loading, setLoading] = useState(true);
	const [loadingFlors, setLoadingFlors] = useState(true);
	const [dataCerimonia, setDataCerimonia] = useState();
	const { key } = useParams();

	let location = useLocation();

	useEffect(() => {
		if (location.state?.to) {
			window.scrollTo(0, 0);
			if (!loading || !loadingFlors) {
				scroller.scrollTo(location.state.to, {
					offset: -100,
				});
			}
		} else window.scrollTo(0, 0);
	}, [location, loading, loadingFlors]);

	useEffect(() => {
		const get = async () => {
			const { difunt, message } = await getDifuntSlug(key);
			console.log(difunt?.publicat);
			if (message === 400) {
				setLoading(false);
				setLoadingFlors(false);
			} else {
				setDifunt(difunt);
				setLoading(false);
				setLoadingFlors(false);
			}
			if (difunt?.publicat === 0) {
				setLoadingFlors(false);
			}
		};
		get();

		return () => {
			setDifunt(null);
		};
	}, [location, setDifunt]);

	useEffect(() => {
		if (difunt?.dia_cerimonia && difunt?.hora_cerimonia) {
			let cerimonia = moment(difunt?.dia_cerimonia, "YYYY-MM-DD");
			cerimonia.hour(difunt?.hora_cerimonia.split(":")[0]);
			cerimonia.minutes(difunt?.hora_cerimonia.split(":")[1]);
			setDataCerimonia(moment().diff(cerimonia.subtract(1, "hour"), "minutes"));
		} else {
			setDataCerimonia(-1);
		}
	}, [difunt]);
	return (
		<Box maxWidth="sm">
			<Perfil loading={loading} loadingFlors={loadingFlors} difunt={difunt} dataCerimonia={dataCerimonia} />
			{difunt?.publicat === 1 && dataCerimonia < 0 && (
				<>
					<Degradat type="up" />
					<Flors difunt={difunt?.id} loadingFlors={loadingFlors} setLoadingFlors={setLoadingFlors} />
					<Degradat type="down" />
					<Notes difunt={difunt?.id} />
				</>
			)}
			<Footer />
		</Box>
	);
};

export default DifuntPublic;
