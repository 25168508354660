import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import Page from '../../../components/Page';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Title from '../../../components/Title';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import OrderState from '../../../components/OrderState';
import { TableContainer } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { useFormik } from 'formik';
import {
    changeFloristeria,
    changeState,
    getFloristeries,
    getOrder,
    getOrderStates,
} from '../../../database/API';
import { Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
    },
    container: {
        paddingTop: 40,
    },
    '@global': {
        html: {
            width: '100%',
            height: '100%',
        },
    },
    formControl: {
        marginTop: 16,
        marginBottom: 15,
    },
    column: {
        backgroundColor: 'white',
        borderRadius: 5,
        padding: 30,
        boxShadow:
            '-1px 2px 4px -1px rgb(0 0 0 / 3%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
    },
    imatge: {
        width: '100%',
        borderRadius: 30,
    },
    difunt: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    difuntInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    difuntImatge: {
        width: 100,
        height: 100,
        marginRight: 30,
    },
    hr: {
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        borderStyle: 'solid',
    },
}));
const OrderView = () => {
    const classes = useStyles();
    const { state } = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [empresa, setEmpresa] = useState([]);
    const [states, setStates] = useState([]);
    const [floristeries, setFloristeries] = useState([]);
    const [order, setOrder] = useState(state);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const formik = useFormik({
        initialValues: {
            order_state_id: order.order_state_id,
        },
        onSubmit: async (values) => {
            const { message } = await changeState(values, order.id);
            const { newOrder } = await getOrder(order.id);
            setOrder(newOrder);
            enqueueSnackbar(message, {
                variant: 'success',
            });
        },
    });

    const formik_floristeria = useFormik({
        initialValues: {
            floristeria_id: order.floristeria_id,
        },
        onSubmit: async (values) => {
            const { message } = await changeFloristeria(values, order.id);
            const { newOrder } = await getOrder(order.id);
            setOrder(newOrder);
            enqueueSnackbar(message, {
                variant: 'success',
            });
        },
    });

    const exportar = () => {
        enqueueSnackbar("S'ha copiat el text", {
            variant: 'success',
        });
    };

    useEffect(() => {
        const get = async () => {
            const { orderStates } = await getOrderStates();
            setStates(orderStates);
        };
        get();
    }, []);

    useEffect(() => {
        const get = async () => {
            const { floristeries } = await getFloristeries();
            setFloristeries(floristeries);
        };
        get();
    }, []);

    useEffect(() => {
        console.log(order);
        if (order.factura === 1) {
            setEmpresa([
                { nom: 'Nom Empresa', valor: order.nom_empresa },
                { nom: 'DNI/NIF', valor: order.dni },
                { nom: 'Adreça', valor: order.adreca },
                {
                    nom: 'Població',
                    valor: order.poblacio + ' (' + order.codi_postal + ')',
                },
                { nom: 'Província', valor: order.provincia },
                { nom: 'País', valor: order.pais },
            ]);
        }
    }, [order]);

    const usuari = [
        { nom: 'Nom', valor: order.nom },
        { nom: 'E-mail', valor: order.email },
        { nom: 'Telèfon', valor: order.telefon },
    ];

    const flor = [
        { nom: 'Nom', valor: order.flor.nom },
        { nom: 'Mida', valor: order.flor.descripcio },
        { nom: 'Preu', valor: order.flor.preu + ' €' },
    ];

    return (
        <Page className={classes.root} title={'Resum comanda'}>
            <Container maxWidth={false} className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <Box mb={3}>
                            <Title
                                title={'Resum comanda - ID: ' + order.id}
                                button={
                                    <Box display="flex" alignItems="center">
                                        <Typography style={{ marginRight: 20 }}>
                                            {moment(order.created_at).format(
                                                'LLL'
                                            )}
                                        </Typography>

                                        <OrderState
                                            state={order.order_state.state}
                                        />
                                    </Box>
                                }
                            />
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                <Box className={classes.column}>
                                    <Box mb={3}>
                                        <Typography variant="h2">
                                            Difunt
                                        </Typography>
                                    </Box>
                                    <Grid container>
                                        <Grid item md={8} xs={12}>
                                            <Box className={classes.difuntInfo}>
                                                <Avatar
                                                    variant="circular"
                                                    className={
                                                        classes.difuntImatge
                                                    }
                                                    src={
                                                        'https://api.funerariaferran.com/public/storage/' +
                                                        order.difunt.imatge
                                                    }
                                                />
                                                <Box>
                                                    <Typography
                                                        className={classes.nom}
                                                        variant="h3"
                                                    >
                                                        {order.difunt.nom}
                                                    </Typography>
                                                    <Typography
                                                        className={classes.nom}
                                                        variant="body1"
                                                    >
                                                        {order.difunt.sobrenom}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Box
                                                justifyContent={'flex-end'}
                                                height={'100%'}
                                                alignContent={'center'}
                                                display={'flex'}
                                                alignItems={'center'}
                                            >
                                                <Button
                                                    onClick={() =>
                                                        navigate(
                                                            '/admin/difunts/' +
                                                                order.difunt.id
                                                        )
                                                    }
                                                    variant="contained"
                                                >
                                                    Veure difunt
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className={classes.column} my={4}>
                                    <Box mb={3}>
                                        <Typography variant="h2">
                                            Dades
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item md={6}>
                                            <Box mb={3}>
                                                <Typography variant="h3">
                                                    Client
                                                </Typography>
                                            </Box>
                                            <TableContainer>
                                                <Table
                                                    sx={{ minWidth: 650 }}
                                                    aria-label="simple table"
                                                >
                                                    <TableBody>
                                                        {usuari.map((row) => (
                                                            <TableRow
                                                                key={row.nom}
                                                            >
                                                                <TableCell
                                                                    align="left"
                                                                    style={{
                                                                        maxWidth: 200,
                                                                        width: 200,
                                                                    }}
                                                                >
                                                                    {row.nom}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    style={{
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    {row.valor}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item md={6}>
                                            {state.factura === 1 && (
                                                <>
                                                    <Box mb={3}>
                                                        <Typography variant="h3">
                                                            Dades de facturació
                                                        </Typography>
                                                    </Box>
                                                    <TableContainer>
                                                        <Table
                                                            sx={{
                                                                minWidth: 650,
                                                            }}
                                                            aria-label="simple table"
                                                        >
                                                            <TableBody>
                                                                {empresa.map(
                                                                    (row) => (
                                                                        <TableRow
                                                                            key={
                                                                                row.nom
                                                                            }
                                                                        >
                                                                            <TableCell
                                                                                align="left"
                                                                                style={{
                                                                                    maxWidth: 200,
                                                                                    width: 200,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    row.nom
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="left"
                                                                                style={{
                                                                                    fontWeight: 600,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    row.valor
                                                                                }
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={4}>
                                <Box className={classes.column}>
                                    <Typography variant="h2">
                                        Canvi d'estat
                                    </Typography>
                                    <Box m={3}></Box>
                                    <form onSubmit={formik.handleSubmit}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={
                                                    formik.values.order_state_id
                                                }
                                                name="order_state_id"
                                                onChange={formik.handleChange}
                                            >
                                                {states?.map((item) => {
                                                    return (
                                                        <MenuItem
                                                            value={item.id}
                                                        >
                                                            {item.state}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                        <Box my={3}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                type="submit"
                                            >
                                                Canviar
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>
                                <Box className={classes.column} my={4}>
                                    <Typography variant="h2">
                                        Floristeria
                                    </Typography>
                                    <Box m={3}></Box>
                                    <form
                                        onSubmit={
                                            formik_floristeria.handleSubmit
                                        }
                                    >
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={
                                                    formik_floristeria.values
                                                        .floristeria_id
                                                }
                                                name="floristeria_id"
                                                onChange={
                                                    formik_floristeria.handleChange
                                                }
                                            >
                                                <MenuItem
                                                    value="0"
                                                    style={{
                                                        fontStyle: 'italic',
                                                    }}
                                                >
                                                    No especificada
                                                </MenuItem>
                                                {floristeries?.map((item) => {
                                                    return (
                                                        <MenuItem
                                                            value={item.id}
                                                        >
                                                            {item.nom}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                        <Box my={3}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                type="submit"
                                            >
                                                Canviar
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>
                                <Box className={classes.column} my={4}>
                                    <Box mb={3}>
                                        <Typography variant="h2">
                                            Flor
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={3}>
                                        <Grid item md={5}>
                                            <img
                                                className={classes.imatge}
                                                alt={order.flor.nom}
                                                src={
                                                    'https://api.funerariaferran.com/public/storage/' +
                                                    order.flor.imatge
                                                }
                                                title={order.flor.nom}
                                            />
                                        </Grid>
                                        <Grid item md={7}>
                                            <TableContainer>
                                                <Table
                                                    sx={{ minWidth: 650 }}
                                                    aria-label="simple table"
                                                >
                                                    <TableBody>
                                                        {flor.map((row) => (
                                                            <TableRow
                                                                key={row.nom}
                                                            >
                                                                <TableCell align="left">
                                                                    {row.nom}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    style={{
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    {row.valor}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Box m={2} style={{ width: '100%' }}>
                                            {order.flor.flor_categoria_id ===
                                            5 ? (
                                                <Typography>
                                                    Nota de condol
                                                </Typography>
                                            ) : (
                                                <Typography>
                                                    Text cinta
                                                </Typography>
                                            )}
                                            <hr className={classes.hr} />
                                            <Typography>
                                                {order.escrit}
                                            </Typography>
                                            <Box mt={3}>
                                                <CopyToClipboard
                                                    text={order.escrit}
                                                    onCopy={exportar}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                    >
                                                        Copiar text
                                                    </Button>
                                                </CopyToClipboard>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default OrderView;
